@import '../../../../styles/shared';

.step_item {
  width: 470px;
  padding: 40px;
  margin-bottom: auto;
  margin-top: auto;
}

.form_row {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.form_group {
  margin-bottom: 40px;
}

.form_divider {
  border-bottom: 1px solid var(--color-border);
}

.radio_description {
  margin-left: 24px;
  margin-bottom: 24px;
}

.form_description {
  font-size: 14px;
  line-height: 18px;
  color: var(--color-form-description);
}

.form_group_description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  color: var(--color-form-label);
}

.form_control {
  margin-top: 16px;
}

.form_label,
.form_label label {
  font-size: 18px;
  line-height: 24px;
  color: var(--color-form-label);
}

.input_with_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  .input_label {
    font-size: 14px;
    line-height: 17px;
    color: var(--color-form-label);
  }

  .number_input {
    width: 120px;
  }
}

.input_amount {
  margin-top: 40px;

  .input_label {
    font-size: 16px;
  }

  .number_input {
    width: 176px;
  }
}

.control_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.control_label {
  font-size: 14px;
  line-height: 17px;
  color: var(--color-base-transparent-64);
  margin-bottom: 4px;
}

.datepicker {
  width: 100%;
}

.alias__icon {
  margin-left: 10px;
  vertical-align: middle;
  color: var(--color-form-title-icon);
}

.alias__tooltip {
  max-width: 400px;
}

.discount_type {
  label {
    padding-left: 0;
    color: var(--color-form-label) !important;
  }

  &_disabled {
    opacity: 0.5;
  }
}
