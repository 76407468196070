@import '../../../styles/shared';

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.row {
  display: flex;

  padding: 24px 0;
}

.controls {
  display: flex;
}

.refundedHeader {
  text-align: center;
  white-space: normal;
}

.search {
  flex-grow: 1;

  margin-left: 24px;
}

.btn {
  padding: 12px 16px;
}

.icon {
  margin-right: 4px;
}

.table__empty {
  height: 100%;
}

.cell__center {
  @extend %center;
}

.cell__order_time {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.point {
  display: inline-block;
  width: 8px;
  height: 8px;

  border-radius: 50%;
  border: 1px solid var(--color-actions);

  &__active {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
}

.btn__switcher {
  width: 12px;
  height: 12px;

  border-radius: 50%;
  background-color: var(--color-base-transparent-40);

  &__active {
    background-color: var(--color-primary);
  }
}
