@import '../../../../styles/shared';
@import '../../../../styles/variables';

.section__info {
  grid-column: 1 / 10;
  overflow: auto;
  @extend %scrollbar;

  @include respond-to(tablet) {
    padding: 24px 15px;
  }
}

.balance {
  display: flex;

  @include respond-to(tablet) {
    height: 150px;
    flex-direction: column;
    justify-content: space-between;
  }
}

.btn {
  width: max-content;
  padding: 12px 16px;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}

.btn__text {
  color: var(--color-primary);
  padding: 12px 8px;
  &__password {
    padding-left: 0;
  }
}

.balance__value {
  margin-right: auto;
  font-size: 32px;
  color: var(--color-base);
}

.select {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-bottom: 28px;
  &:last-of-type {
    order: 2;
  }
  &:nth-of-type(2) {
    order: 3;
  }
  &:nth-of-type(3) {
    order: 4;
  }

  @include respond-to(tablet) {
    display: flex;
    flex-direction: column;
  }
}
.select__label {
  font-size: 18px;
  color: var(--color-title-second);
}
.select__field {
  border-bottom: 1px solid var(-color-default-100);
  padding: 14px 0;
  margin-bottom: 24px;
  @include respond-to(tablet) {
    margin-bottom: 18px;
  }
}
.form__contacts {
  display: grid;
  grid-template-columns: 0.25fr 0.75fr;
  grid-auto-rows: 40px;
  grid-gap: 25px;

  @include respond-to(tablet) {
    display: flex;
    flex-direction: column;
    grid-gap: unset;
  }
}

.form__fieldLabel {
  align-self: center;

  font-size: 14px;
  color: var(--color-title-second);
  opacity: 0.8;

  @include respond-to(tablet) {
    align-self: flex-start;
    margin-bottom: 8px;
  }
}

.control {
  max-width: 305px;

  label {
    display: none;
  }

  input {
    font-size: 16px;

    @include respond-to(tablet) {
      color: var(--color-base);
      margin-bottom: 24px;
    }
  }

  input:read-only {
    cursor: default;
    outline: none;
    border: none;

    padding: 0;

    text-overflow: ellipsis;
  }
}

.controls {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 24px;
  & .btn:first-child {
    margin-right: 16px;
  }
}
