.wrapper {
  position: relative;

  input {
    padding-right: 48px;
  }
}

.icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-base-transparent-32);
  cursor: pointer;
}
