@import '../../styles/shared';

.tabs {
  border-bottom: 1px solid var(--color-base-transparent-10);

  @media print {
    display: none;
  }
}

.tab {
  position: relative;
  display: inline-block;

  height: 34px;
  padding: 0 28px;

  color: var(--color-base-transparent-64);
  text-align: center;

  transition: opacity 0.3s ease;
}

.tab__active {
  color: var(--color-base);
  box-shadow: 0 4px 0 0 var(--color-primary);
}

.description {
  @extend %text-ellipsis;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 6px;
  text-align: center;
  color: var(--color-base-transparent-40);
  font-size: 12px;
}
