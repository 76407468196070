@import '../../../styles/shared';

.modal :global {
  .rc-dialog-content {
    min-width: 500px;
  }
}

.title {
  font-size: 16px;
  padding: 24px 40px;

  color: var(--color-base-transparent-80);
}

.form {
  padding: 24px 40px;
}

.form__line {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  color: var(--color-base);

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
}

.control {
  margin-bottom: 32px;
}

.controls {
  box-shadow: $box-shadow-line-top;
  padding: 24px 40px;
}

.btn {
  padding: 12px 24px;

  &:not(:first-child) {
    margin-left: 16px;
  }
}

.model {
  background-color: transparent;
}

.form__group {
  padding: 24px 40px;
}

.form__section {
  margin-bottom: 16px;
}

.form__section__title {
  margin-bottom: 18px;
  font-size: 16px;

  color: var(--color-base-transparent-80);
}

.form__control {
  width: 200px;
}

.form__control :global {
  .react-timerange-picker__wrapper {
    width: 200px !important;
  }
}

.form__label {
  position: relative;
  padding-right: 24px;
  margin-bottom: 12px;
  font-size: 14px;
  color: var(--color-base-transparent-64);
  line-height: 20px;

  &__hint {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    vertical-align: middle;
    color: var(--color-form-title-icon);

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
