@import '../../../styles/shared';

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.row {
  display: flex;

  padding: 24px 0;
}

.model__search {
  flex-grow: 1;

  margin-left: 24px;
}

.btn {
  min-width: 150px;
  padding: 12px 16px;
}

.btn__search {
  min-width: 120px;
  padding: 12px 16px;
}

.icon {
  margin-right: 4px;
}

.btn__copy {
  color: var(--color-primary);
}

.cell__text {
  @extend %text-ellipsis;
}
